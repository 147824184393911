import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Console } from 'console';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { slideInOutAnimation, fadeInOutAnimation } from 'src/app/animations/animations';
import { IEffectiveness } from 'src/app/interfaces/models/IEffectiveness';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { icons } from 'src/app/utils/icons';
import { environment } from 'src/environments/environment';

export interface ActivityFilters {
  suggestion?: string;
  regionals?: string;
  directors?: string;
  cities?: string;
  states?: string;  
  clusters?: string;
  subclusters?: string;  
}

export const hierarchyRanking = {
  regionals: { hierarchy: 0 },
  directors: { hierarchy: 1 },
  states: { hierarchy: 2 },
  clusters: { hierarchy: 3 },
  subclusters: { hierarchy: 4 },
  cities: { hierarchy: 5 },    
  initiatives: { hierarchy: null },
}

export const defaultActiveFilters: ActivityFilters = {
  suggestion: '',
  regionals: '',
  directors: '',
  cities: '',
  states: '',  
  clusters: '',
  subclusters: '',  
};


export interface DropdownFiltersNew {
  suggestion: string[];
  states: string[];
  regionals: string[];
  directors: string[],
  cities:string[],      
  clusters:string[],
  subclusters:string[],  
}

export interface selectedFiltersNew {
  suggestion: string[];
  states: string[];
  regionals: string[];
  directors: string[],
  cities:string[],    
  clusters:string[],
  subclusters:string[],  
}

@Component({
  selector: 'app-sugestao',
  templateUrl: './sugestao.component.html',
  styles: [],
  animations: [slideInOutAnimation, fadeInOutAnimation]
})
export class SugestaoComponent implements OnInit {
  NAMESPACE: Namespace = 'effectiveness';

  suggestionData: BehaviorSubject<IEffectiveness[]> = new BehaviorSubject([]);  
  activeFilters: BehaviorSubject<ActivityFilters> = new BehaviorSubject(defaultActiveFilters);
  selectedFilters: BehaviorSubject<selectedFiltersNew> = new BehaviorSubject<selectedFiltersNew>({
    suggestion: ['PEAD'],    
    regionals: [],
    directors: [],
    cities: [],
    states: [],    
    clusters: [],
    subclusters: [],    
  });
  dropdownFilters: BehaviorSubject<DropdownFiltersNew> = new BehaviorSubject<DropdownFiltersNew>({
    suggestion: [],    
    regionals: [],
    directors: [],
    cities: [],
    states: [],    
    clusters: [],
    subclusters: [],    
  });

  averageEffectiveness: number = 0; 
  currentPage: number = 1;
  totalPages: number = 1; 
  loading: boolean = false;
  error: boolean = false;
  isDivVisible: boolean = false;
  icons = icons;
  iconBackHome = '/assets/icons/back-home.png';
  iconBackHomeWhite = '/assets/icons/back-home-white.png';

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.resetFilters();
    this.getSuggestion(0, this.activeFilters.value);    
  }

  resetFilters(): void {    
    this.activeFilters.next({ ...defaultActiveFilters });
  }
  
  onPageChange(newPage: number) {
    this.getSuggestion(newPage - 1, this.activeFilters.value);
  }  

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  } 
  
  onOpenMap(event: { lat: number; lng: number }){    
    const currentFilters = this.activeFilters.value;    
    this.filterService.setFilters(currentFilters)
    

    // Navegue para o mapa com as coordenadas e filtros aplicados
    this.router.navigate(['/vandalismo/mapa'], {
      queryParams: {
        lat: event.lat,
        lng: event.lng,        
      }
    });
  }
  
  
  getSuggestion(page: number, filters: ActivityFilters) {
    this.loading = true;
    this.error = false;    
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}/vandalism/suggestion`;

    const body = {
      suggestion: filters.suggestion || 'PEAD',
      regionals: filters.regionals || '',
      directors: filters.directors || '',
      cities: filters.cities || '',
      states: filters.states || '',      
      clusters: filters.clusters || '',
      subclusters: filters.subclusters || '',      
      pages: page
    };

    // console.log('filtros do body:', body);

    return this.http
      .post<any>(url, body)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        if (response) {
          this.suggestionData.next(response.body);

          const newDropdownFilters: DropdownFiltersNew = {
            suggestion: response.filters.suggestion || [],
            regionals: response.filters.regionals || [],
            directors: response.filters.directors || [],
            cities: response.filters.cities || [],
            states: response.filters.states || [],            
            clusters: response.filters.clusters || [],
            subclusters: response.filters.subclusters || [],            
          };
          
          const newSelectedFilters: selectedFiltersNew = {
            suggestion: newDropdownFilters.suggestion.length === 1 ? [newDropdownFilters.suggestion[0]] : [],
            regionals: newDropdownFilters.regionals.length === 1 ? [newDropdownFilters.regionals[0]] : [],
            directors: newDropdownFilters.directors.length === 1 ? [newDropdownFilters.directors[0]] : [],
            cities: newDropdownFilters.cities.length === 1 ? [newDropdownFilters.cities[0]] : [],
            states: newDropdownFilters.states.length === 1 ? [newDropdownFilters.states[0]] : [],            
            clusters: newDropdownFilters.clusters.length === 1 ? [newDropdownFilters.clusters[0]] : [],
            subclusters: newDropdownFilters.subclusters.length === 1 ? [newDropdownFilters.subclusters[0]] : [],            
          };          

          this.dropdownFilters.next(newDropdownFilters);
          this.selectedFilters.next(newSelectedFilters);
          this.activeFilters.next(this.buildActiveFilters(newSelectedFilters));
          
          this.averageEffectiveness = response.averageEffectiveness;
          this.totalPages = response.pagesNumber;
          this.currentPage = page + 1;
        }
      });
  } 
  
  buildActiveFilters(selectedFilters: selectedFiltersNew): ActivityFilters {
    return {
      suggestion: selectedFilters.suggestion.length > 0 ? selectedFilters.suggestion.join(',') : '',
      regionals: selectedFilters.regionals.length > 0 ? selectedFilters.regionals.join(',') : '',
      directors: selectedFilters.directors.length > 0 ? selectedFilters.directors.join(',') : '',
      states: selectedFilters.states.length > 0 ? selectedFilters.states.join(',') : '',
      clusters: selectedFilters.clusters.length > 0 ? selectedFilters.clusters.join(',') : '',
      subclusters: selectedFilters.subclusters.length > 0 ? selectedFilters.subclusters.join(',') : '',
      cities: selectedFilters.cities.length > 0 ? selectedFilters.cities.join(',') : '',      
    };
  }

  // Função de onChange simplificada, agora lidando apenas com activeFilters
  onChange(event: string) {
    const [key, value] = event.split(':');
    const currentFilters = { ...this.activeFilters.value };
    currentFilters[key] = value;

      if (value === '') {
        const keyHierarchy = hierarchyRanking[key]?.hierarchy;

        if (keyHierarchy !== undefined && keyHierarchy !== null) {
            Object.keys(hierarchyRanking).forEach(hierarchyKey => {
                const hierarchyLevel = hierarchyRanking[hierarchyKey].hierarchy;
                if (hierarchyLevel !== null && hierarchyLevel > keyHierarchy) {
                    currentFilters[hierarchyKey] = '';
                }
            });
        }
    }

    this.getSuggestion(0, currentFilters);
  }   

}
