import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, finalize } from 'rxjs/operators';
import { AnalyticalGeneral } from 'src/app/interfaces/dtos/AnalyticalGeneral';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { ThemeService } from 'src/app/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-vision',
  templateUrl: './general-vision.component.html',
  styleUrls: ['./general-vision.component.sass']
})
export class GeneralVisionComponent implements OnInit {

  NAMESPACE: Namespace = 'analytical';
  data: BehaviorSubject<AnalyticalGeneral.Output> = new BehaviorSubject(new AnalyticalGeneral.Output);
  currentState = new BehaviorSubject('EMPRESARIAL');
  activeFilters;
  loading: boolean = true;
  error: boolean = false;
  onChange: any;
  barColors: string[];
  colors: string[];
  primaryColor: string = '#ff0000';
  secondaryColor: string = '#000000';

  constructor(
    private themeService: ThemeService,
    private filterService: FilterService,
    private httpService: HttpClient
  ) {
    this.colors = ['gray', 'black'];
    this.barColors = this.themeService.generateColorGradient(this.primaryColor, this.secondaryColor, 4);
    
    this.filterService.getFiltersObservable<AnalyticalGeneral.InputParams>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((af) => this.updateData(af));
  }
  
  ngOnInit(): void { 
  }

  updateData(activeFilters) {
    this.activeFilters = activeFilters;
    this.loading = true;
    const baseUrl = environment.baseUrl;
    const mappedFilters = {
      "years": activeFilters.year,
      "months": activeFilters.month,
      "days": activeFilters.day,
      "causes": activeFilters.causeGroup,
      "regionals": activeFilters.regional,
      "directors": activeFilters.group,
      "states": activeFilters.state,
      "subClusters": activeFilters.subcluster,
      "clusters": activeFilters.cluster,
      "cities": activeFilters.city,
      "families": activeFilters.family,
      "nets": activeFilters.net
    }
    this.httpService
      .post<AnalyticalGeneral.Response>(`${baseUrl}/vandalism/analytical/general`, mappedFilters)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        this.loading = false;
        this.data.next(new AnalyticalGeneral.Output(response))
      })
  }

  formatCityName(str: string) {
    return str
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
