import { AccessControl } from "src/app/interfaces/dtos/AccessControl";
import { AccessControlByDirectory } from "src/app/interfaces/dtos/AccessControlByDirectory";
import { Formatter } from "src/app/utils/formatter.util";
import { GenerateDate } from "src/app/utils/generateDate.util";

export const initialDataDefault = {
  message: '',
  filters: { directors: [], operations: [], companies: [] },
  data: []
}

const { startDate, endDate } = GenerateDate.getDates();

export const defaultActiveFilters: AccessControl.Params = {
  startDate: Formatter.formatDataPickerDate(startDate),
  endDate: Formatter.formatDataPickerDate(endDate),
  directors: "",
  operations: "login",
  companies: ""
}
