<div>
  <div>
    <div class="row h-20 selects-row">
  
      <div class="col-lg-4 col-md-4">
        <app-data-picker-default
          [dates]="getRangeDate()"
          (event)="onChange($event)"
        >
        </app-data-picker-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="operations"
          title="Operações"
          [dropdownList]="this.data.value.filters[0]"
          [selectedItems]="['login']"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="directors"
          title="Diretorias"
          [dropdownList]="this.data.value.filters[1]"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div *ngIf="isAdmin()" class="col-lg-2 col-md-2">
        <app-select-default
          key="companies"
          title="Empresas"
          [dropdownList]="this.data.value.filters[2]"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>
  
      <div class="col-lg-2 col-md-2"></div>
  
      <div class="col-lg-2 col-md-2"></div>
    </div> <!-- selects filters -->

    <div *ngIf="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  
    <div *ngIf="error" class="alert alert-danger" role="alert">
      Ocorreu um erro ao carregar os usuários.
    </div>

    <div class="section-margin" *ngIf="!loading && !error">
      <div class="row">
        <div class="custom-card" style="height: 75vh">
    
          <div class="custom-card-header">
            <h5 class="header-title">Listagem de acessos por usuário</h5>
            <input
              type="text"
              class="form-control custom-search-input"
              placeholder="Buscar usuário"
              [(ngModel)]="searchTerm"
              (ngModelChange)="applyFilter()"
            />
          </div>
      
          <div class="custom-card-body table-responsive">
            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-center text-nowrap">Identificação</th>
                  <th class="text-center text-nowrap">Nome de usuário</th>
                  <th class="text-center text-nowrap">Quantidade de logs</th>
                  <th class="text-center text-nowrap">Empresa</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let log of filteredLogs"
                  (click)="openModal(log.userId, log.username)"
                >
                  <td class="text-center">{{ log.userId }}</td>
                  <td class="text-center">{{ log.username }}</td>
                  <td class="text-center">{{ log.count }}</td>
                  <td class="text-center">{{ log.company }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>   
</div>