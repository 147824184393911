<div class="container-table">
  <table class="table-container" >
    <thead class="table-thead">
      <tr class="table-thead-tr">
        <th class="table-thead-th">Iniciativa</th>
        <th class="table-thead-th">Id iniciativa</th>
        <th class="table-thead-th">Empresa</th>
        <th class="table-thead-th">Técnico</th>
        <th class="table-thead-th">Regional</th>
        <th class="table-thead-th">Baixas anteriores</th>
        <th class="table-thead-th">Baixas posteriores</th>
        <th class="table-thead-th">Quantidade</th>
        <th class="table-thead-th">Efetividade</th>
      </tr>
    </thead>
    <tbody class="table-tbody">

      <!-- Loading -->

      <div class="loading-table" *ngIf="isLoading" >
        <div class="loading-animation">
          <div class="spinner-container">        
            <div class="spinner"></div>
          </div>
          <p class="loading-title">Carregando...</p>
        </div>
      </div>

      <div *ngFor="let item of effectivenessData | async; let i = index">
        <tr 
          class="table-tbody-tr" 
          *ngIf="!isLoading"
          (click)="toggleDropdown(i)"
        >          
          <div 
            class="tr-effectiveness-color"
            [ngClass]="{
              'high-percentage': getPercentageValue(item.effectiveness) >= 70,
              'medium-percentage': getPercentageValue(item.effectiveness) >= 40 && getPercentageValue(item.effectiveness) < 70,
              'low-percentage': getPercentageValue(item.effectiveness) < 40
            }"
          ></div>
          <th class="table-tbody-th">{{item.initiative}}</th>
          <th class="table-tbody-th">{{item.effectivenessId}}</th>
          <th class="table-tbody-th-company">{{item.company}}</th>
          <th class="table-tbody-th">{{item.resourceId}}</th>
          <th class="table-tbody-th">{{item.regional}}</th>
          <th class="table-tbody-th">{{item.casesBefore}}</th>
          <th class="table-tbody-th">{{item.casesAfter}}</th>
          <th class="table-tbody-th">{{item.quantity}}</th>
          <th class="table-tbody-th">{{item.effectiveness}}%</th>
        </tr>

        <!-- MORE INFORMATION -->
        <tr 
          class="table-tbody-tr-more"
          [ngClass]="{'show': activeRow === i}" 
        >          
          <div 
            class="tr-effectiveness-color"
            [ngClass]="{
              'high-percentage': getPercentageValue(item.effectiveness) >= 70,
              'medium-percentage': getPercentageValue(item.effectiveness) >= 40 && getPercentageValue(item.effectiveness) < 70,
              'low-percentage': getPercentageValue(item.effectiveness) < 40
            }"
          ></div>
          <div class="tr-more-info">
            <span class="tr-more-info-span">cluster</span>
            <th  class="tr-more-info-th">{{item.cluster}}</th>
          </div>
          <div class="tr-more-info">
            <span class="tr-more-info-span">subcluster</span>
            <th  class="tr-more-info-th">{{item.subcluster}}</th>
          </div>
          <div class="tr-more-info">
            <span class="tr-more-info-span">estado</span>
            <th  class="tr-more-info-th">{{item.state}}</th>
          </div>
          <div class="tr-more-info">
            <span class="tr-more-info-span">cidade</span>
            <th  class="tr-more-info-th">{{item.city}}</th>
          </div>
          <div class="tr-more-info info-address">
            <span class="tr-more-info-span">endereço</span>
            <th  class="tr-more-info-th">{{item.address}}</th>
          </div>
        </tr>
      </div>
      
    </tbody>
  </table>

  <!-- Paginação -->
  <div class="container-pages" *ngIf="!isLoading">    
    <button             
      [ngClass]="{'button-pages': true, 'button-pages-disabled': currentPage <= 1}"      
      (click)="goToPrevious10Pages()" 
      [disabled]="currentPage <= 10"
    >
      <i class="fa fa-chevron-left"></i>
      <span>10</span>
    </button>

    <button       
      [ngClass]="{'button-pages': true, 'button-pages-disabled': currentPage <= 1}"      
      (click)="goToPreviousPage()" 
      [disabled]="currentPage === 1"
    >
      <i class="fa fa-chevron-left"></i>
      <span>anterior</span>
    </button>
    
    <div class="container-counter-pages">      

      <div class="custom-number-input">      
        <div>
          <span class="span-input">Página </span>          
          <input
            type="number"
            [(ngModel)]="currentPage"
            (ngModelChange)="onPageChange(currentPage)"
            min="1"
            [max]="totalPages"          
            class="page-input"
          />        
          <span class="span-input"> de </span>
          <span class="span-input-number">{{totalPages}}</span>
        </div>
      </div>      
    </div>

    <button       
      class="button-pages" 
      (click)="goToNextPage()" 
      [disabled]="currentPage === totalPages"
    >
      <span>próximo</span>
      <i class="fa fa-chevron-right"></i>
    </button>        

    <button
      class="button-pages"
      (click)="goToNext10Pages()"
      [disabled]="currentPage === totalPages"
    >
      <span>10</span>        
      <i class="fa fa-chevron-right"></i>
    </button>    
  </div>
</div>