<div 
  class="container-header-page" 
  [class.bg-transition]="isFilter"
  #headerFilter
>
  <div class="header-img-filter col-1">
    <img         
      [src]="isFilter ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
      (click)="openFilters()"
      alt="Logo da claro"
    >       
  </div>
  <div
    class="container-header-filters col-11"
    [@slideInOut]="isFilter ? 'in' : 'out'"
  >
    <div class="container-filters col-12 p-0">
      <!-- <app-filter-default
        key="regional"
        filterTitle="Regional"
        [icon]="icons.building"

      >
      </app-filter-default> -->
    </div>
  </div>
</div>
