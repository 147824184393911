<div class="row no-gutters">
	<div class="header-page d-none d-lg-block">
		<div class="row h-20 selects-row">
			<div class="col-lg-4 col-md-4">
				<app-data-picker-default
					[dates]="getRangeDate()"
				  (event)="onChange($event)"
				>
				</app-data-picker-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					#selectCause
					key="causes"
					title="Causas"
					[dropdownList]="['Vandalismo']"
					[selectedItems]="['Vandalismo']"
					[itemsShowLimit]="2"
					isDisabled="true"
					(event)="onChange($event)"
				>
				</app-select-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="regionals"
					title="Regionais"
					[dropdownList]="data.value?.getFilters().regionals"
					[itemsShowLimit]="2"
					(event)="onChange($event)"
				>
				</app-select-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="directors"
					title="Diretorias"
					[dropdownList]="data.value?.getFilters().directors"
					[itemsShowLimit]="1"
					(event)="onChange($event)"
				>
				</app-select-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="states"
					title="Estados"
					[dropdownList]="data.value?.getFilters().states"
					[itemsShowLimit]="3"
					(event)="onChange($event)"
				>
				</app-select-default>
			</div>
		</div> <!-- selects filters -->

		<div class="row cards-row d-flex justify-content-end">

			<div class="col-lg-3 col-md-3 col-xxl-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.headset"
						[cardTitle]="'Número de chamados'"
						[cardValue]="data.value?.getCards().ticketsQuantity">
					</app-card-default>
				</app-request-wrapper>
			</div>

			<div class="col-lg-3 col-md-3 col-xxl-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.clock"
						[cardTitle]="'Média de chamados por dia'"
						[cardValue]="data.value?.getCards().avgNumberCallsPerDay">
					</app-card-default>
				</app-request-wrapper>
			</div>

			<div class="col-lg-3 col-md-3 col-xxl-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.calendarDay"
						[cardTitle]="'Média SLA por hora'"
						[cardValue]="data.value?.getCards().avgSLAinHours">
					</app-card-default>
				</app-request-wrapper>
			</div>
		</div> <!-- cards -->

	</div> <!-- * HEADER PAGE DESKTOP -->

	<div class="header-page-mobile d-block d-sm-none">
		<div class="filter-mobile">
			<button
				class="btn-default"
				(click)="openFiltersPopUp()"
			>
				Filtros
				<span class="fa fa-sliders"></span>
			</button>
		</div>
		<div>
			<div>
				<ngx-slick-carousel
					class="slick-slider slick-dotted"
					#slickModal="slick-carousel"
					[config]="slideConfig"
				>
					<div ngxSlickItem >
						<div class="col-10 mx-auto">
							<app-card-default
								[icon]="icons.headset"
								[cardTitle]="'Número de chamados'"
								[cardValue]="data.value?.getCards().ticketsQuantity">
							</app-card-default>
						</div>
					</div>

					<div ngxSlickItem >
						<div class="col-10 mx-auto">
							<app-card-default
								[icon]="icons.clock"
								[cardTitle]="'Média de chamados por dia'"
								[cardValue]="data.value?.getCards().avgNumberCallsPerDay">
							</app-card-default>
						</div>
					</div>

					<div ngxSlickItem >
						<div class="col-10 mx-auto">
							<app-card-default
								[icon]="icons.calendarDay"
								[cardTitle]="'Média SLA por hora'"
								[cardValue]="data.value?.getCards().avgSLAinHours">
							</app-card-default>
						</div>
					</div>
				</ngx-slick-carousel>
			</div>
		</div>
	</div> <!-- * HEADER PAGE MOBILE -->
	<div class="container-geral row mx-auto px-0">
		<div class="col-12 col-md-8 col-xxl-8">
			<div class="row mx-auto" style="height: 50%;">
				<div class="col-12 col-md-8 col-xxl-8">
					<app-request-wrapper
						[class]="requestWrapperConfig.chartSmallStyle"
						[isLoading]="loading"
						[isError]="error"
					>
						<app-occurrences-bar-chart
							[data]="data.value?.getVandalismOccurrences()"
							[colors]="colors"
							(event)="onRedirect($event)"
						>
						</app-occurrences-bar-chart>
					</app-request-wrapper>
				</div><!-- OCCURRENCE CHART -->
				<div class="col-12 col-md-4 col-xxl-4">
					<app-request-wrapper
						[class]="requestWrapperConfig.chartSmallStyle"
						[isLoading]="loading"
						[isError]="error"
					>
						<app-incident-doughnut-chart
							[data]="data.value.getOperationalPercentages()"
							[colors]="colors"
						>
						</app-incident-doughnut-chart>
					</app-request-wrapper>
				</div><!-- INCIDENT DOUGHT CHART -->
			</div>
			<div class="row mx-auto" style="height: 50%;">
				<div class="col-12 col-md-12 col-xxl-12">
					<app-request-wrapper
						[class]="requestWrapperConfig.chartSmallStyle"
						[isLoading]="loading"
						[isError]="error"
					>
						<app-call-quantity-bar-chart
							[data]="data.value?.getCallsByDayAndHour()"
							[colors]="colors"
						>
						</app-call-quantity-bar-chart>
					</app-request-wrapper>
				</div><!-- CALL QUANTITY CHART -->
			</div> <!-- chart bar  -->
		</div> <!-- lado esquerdo -->
		<div class="col-12 col-md-4 col-xxl-4">
			<div class="card-chart chart-full-geral col-12">
        <app-request-wrapper
					[isLoading]="loading"
					[isError]="error"
				>
					<div class="card-chart-header chart-full-header-geral">
						<app-title-default [content]="'MAPA DE CALOR ABERTURA DE CHAMADOS'"></app-title-default>
					</div>
					<hr>
					<div class="card-chart-body chart-full-body-geral">
						<app-heat-map
							[markers]="data.value?.getTicketsForMap()"
							[namespace]="NAMESPACE"
						>
						</app-heat-map>
					</div>
				</app-request-wrapper>
			</div><!-- CONTAINER HEAT MAP -->
		</div>
	</div><!-- * CONTAINER GEERAL -->
</div>
