<div class="container-ticket-pop-up">
  <div class="popup-header">
    <div class="container-title">
      <div class="popup-title">
        <!-- INCIDENT ID -->
        <h2>Incidente {{ treatLegacyTicket(modalData, 'incidentId') }}</h2>
      </div>
      <div class="popup-btn">
        <button type="button" class="btn-close-default" (click)="closeModal()">
          <span class="fa fa-times-circle"></span>
        </button>
      </div>
    </div>           
    
    <!-- <span *ngIf="effectivenessLine" class="effectiveness-line" [ngClass]="getEffectivenessClass()"></span>      
    <div *ngIf="!effectivenessLine">
    </div>    -->
  </div>

  <div>
    <!-- Botão para gerar PDF e indicador de carregamento -->
    <button
        (click)="generateActivityReportPdf()"
        class="popup-generate-pdf"
        [disabled]="!(photos.length === 5)"
    >
      <span *ngIf="!isLoading">Gerar PDF</span>
      <div class="loader-container" *ngIf="isLoading">
          <div class="spinner-border" role="status"></div>
          <span>Gerando Relatório</span>
      </div>
    </button>
  </div>
  
  <div class="popup-body">

    <div *ngIf="loading" class="loading-container">
      <div class="loader">
        <div class="ball-pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <!--  ROWS INFO TICKET -->
    <div  class="container-row-pop-up" *ngIf="displayData.length != 0">
      <div class="rows-pop-up">
        <div *ngFor="let data of displayData">
          <app-ticket-pop-up-row
            [label]="data.label"
            [value]="data.value"
            [effectivenessFlag]="data.label === 'Efetividade do Pead' && effectivenessLine"
            [effectivenessClass]="data.label === 'Efetividade do Pead' ? getEffectivenessClass() : ''"
          >
          </app-ticket-pop-up-row>
        </div>
      </div>      
    </div>

    <div class="container-carousel" *ngIf="!loading">
      <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig3">
        <div ngxSlickItem *ngFor="let photo of photos; let i = index">

          <!-- CONTAINER BOTÕES DE VALIDAÇÃO -->
          <div class="validation-container">            
            <div class="validation-buttons" *ngIf="isAdmin()">
              <input 
                type="radio"
                id="sharp-{{i}}"
                name="validation-{{i}}"
                (change)="sendPhotoManualReport(i, true)"
                [(ngModel)]="sharpnessValidations[i]"
                [value]="true"
                [checked]="sharpnessValidations[i] === true"
              >
              <label for="sharp-{{i}}" class="sharp-label">Nítida</label>

              <input 
                type="radio"
                id="not-sharp-{{i}}"
                name="validation-{{i}}"
                (change)="sendPhotoManualReport(i, false)"
                [(ngModel)]="sharpnessValidations[i]"
                [value]="false"
                [checked]="sharpnessValidations[i] === false"
              >
              <label for="not-sharp-{{i}}" class="not-sharp-label">Não Nítida</label>
            </div>
          </div>

          <div class="img-popup">
            <img 
              [src]="photo" 
              (click)="openZoomImageModal(photo)" 
              alt="incident"
              class="carousel-image" 
            />
          </div>
        </div>
      </ngx-slick-carousel>
    </div> 

  </div>

  <div class="container-buttons-popup">
      <!-- Contêiner do Boletim de Ocorrência -->
       
      <div class="incident-report-container">
        <!-- Se um arquivo já estiver anexado no servidor, exibe o nome e botões -->
        <div *ngIf="occurrenceFileName" class="file-actions-container">
            <p class="file-name" title="{{ occurrenceFileName }}">
                Arquivo anexado: {{ occurrenceFileName }}
            </p>
            <button (click)="removeReportFile()" class="remove-button">Remover</button>
            <button (click)="exportReport()" class="export-button">Exportar</button>
        </div>

        <div class="file-container">
          <div class="file-upload-container" *ngIf="!occurrenceFileName">
            <!-- Botão de Importar -->
            <button *ngIf="!showFileInput" (click)="toggleFileInput()" class="import-toggle-button">
              Anexar BO
            </button>
            <!-- Input e botões de Importar na mesma linha -->
            <div *ngIf="showFileInput" class="file-import-container">
              <input type="file" (change)="importReport($event)" accept=".pdf" class="file-input">
              <button (click)="importReport()" class="submit-button">Enviar</button>
              <button (click)="toggleFileInput()" class="cancel-button">Cancelar</button>
            </div>
          </div>
        
          <div class="occurrence-report-container">
            <!-- Botão de Registrar Boletim -->
            <button *ngIf="!showInput && !occurrenceFileName" (click)="toggleOccurrenceInput()" class="report-toggle-button">
              Código Eletrônico do BO
            </button>
            <!-- Input e botões de Enviar na mesma linha -->
            <div *ngIf="showInput && !occurrenceFileName" class="file-import-container">
              <input [(ngModel)]="occurrenceReport" placeholder="Digite o Boletim de ocorrência" class="file-input">
              <button (click)="sendOccurrenceReport()" class="submit-button">Enviar</button>
              <button (click)="toggleOccurrenceInput()" class="cancel-button">Cancelar</button>
            </div>
          </div>  
        </div>

        <!-- Botão para visualizar a localização no Google Maps -->
        <button
            *ngIf="isButtonLocations"
            (click)="openGoogleMaps()"
            class="popup-generate-pdf"
            [disabled]="!(photos.length === 5)"
        >
            Vizualizar Localização
        </button>
    </div>

    <button      
      *ngIf="isButtonLocations"
      (click)="openGoogleMaps()"
      class="popup-generate-pdf"
      [disabled]="!(this.photos.length === 5)"
    >
      <span>Vizualizar Localização</span>
    </button>        
  </div>

</div>

<app-image-verification-map
  *ngIf="isMapLoading"
  [locations]="location"
  [imagesUrls]="photos"
  (mapClosed)="onMapClosed()"
>    
</app-image-verification-map>