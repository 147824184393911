import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AnalyticalFilters {
  year: string;
  month: string;
  day: string;
  family: string;
  net: string;
  regional: string;
  state: string;
  group: string;
  cluster: string;
  subcluster: string;
  city: string;
  causeGroup: string;
  isPead: boolean;
  isAccumulated: boolean;
  isSuggestion: boolean;
}

export interface SugestaoFilters {
  suggestion?: string;
  regional?: string;
  directors?: string;
  city?: string;
  state?: string;
  cluster?: string;
  subcluster?: string;
}

export type FilterValues = Record<string, string>

export type Namespace = 'geral' | 'detail' | 'alert' | 'route' | 'fullscreen' | 'analytical' | 'analyticalFullscreen' | 'operationFullscreen' | 'imageVerification' | 'imageAnalytical' | 'inventoryFullscreen' | 'accesscontrol'
| 'effectiveness' | 'accessDirectory'

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private namespaces: { [K in Namespace]?: BehaviorSubject<any> } = {};      
  private filtersSubject = new BehaviorSubject<any>({});
  filters$ = this.filtersSubject.asObservable();

  private analyticalFilters = new BehaviorSubject<AnalyticalFilters>({
    year: '', month: '', day: '', family: '', net: '', regional: '', state: '', group: '', cluster: '',
    subcluster: '', city: '', causeGroup: '', isPead: true, isAccumulated: false, isSuggestion: false
  });

  private sugestaoFilters = new BehaviorSubject<SugestaoFilters>({});

  constructor() {}

  private getOrCreateNamespace<T>({ namespace, defaultFilters }: { namespace: Namespace, defaultFilters: T }): BehaviorSubject<T> {
    if (!this.namespaces[namespace]) {
      this.namespaces[namespace] = new BehaviorSubject<T>(defaultFilters);
    }
    return this.namespaces[namespace];
  }

  updateFilters<T>({ namespace, key, values }: { namespace: Namespace, key: string, values: string | boolean }): void {
    const filters: BehaviorSubject<T> = this.getOrCreateNamespace({
      namespace,
      defaultFilters: {} as T 
    });
    const currentFilters = filters.value;
    filters.next({ ...currentFilters, [key]: values });
  }

  actionChange<T>({ namespace }: { namespace: Namespace }) {
    const filters: BehaviorSubject<T> = this.getOrCreateNamespace({
      namespace,
      defaultFilters: {} as T 
    });
    const currentFilters = filters.value
    filters.next({ ...currentFilters });
  }

  updateMultipleFilters<T>({ namespace, newFilters }: { namespace: Namespace, newFilters: T }): void {
    const filters: BehaviorSubject<T> = this.getOrCreateNamespace({
      namespace,
      defaultFilters: {} as T
    });
    filters.next({ ...newFilters });
  }
  
  setDefaultFilters<T>({ namespace, defaultFilters }: { namespace: Namespace, defaultFilters: T }): void {
    const filters: BehaviorSubject<T> = this.getOrCreateNamespace({
      namespace,
      defaultFilters,
    });
    filters.next(defaultFilters);
  }

  getFiltersObservable<T>({ namespace }: { namespace: Namespace }): Observable<T> {
    return this.getOrCreateNamespace({ namespace, defaultFilters: {} as T }).asObservable();
  }

  clearNamespace({ namespace }: { namespace: Namespace }): void {
    if (this.namespaces[namespace]) {
      this.namespaces[namespace].unsubscribe();
      delete this.namespaces[namespace];
    }
  }

  setFilters(filters:any) {
    this.filtersSubject.next(filters);
    console.log("setFilters",filters);
  }
  
  getFilters() {
    console.log("getFilters",this.filtersSubject.value);
    return this.filtersSubject.value;
  }   
}
