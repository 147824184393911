import { BehaviorSubject } from "rxjs";
import {
  analyticalDropdownList,
  analyticalSelectedList,
  initialStateActiveFilters
} from "src/app/utils/constants";

export namespace VandalismAnalytical {

  type FilterButton = Array<{ show: string, value: string }>;
  export interface Dropdown {
    year: FilterButton,
    day: FilterButton,
    month: FilterButton,
    family: FilterButton
    net: FilterButton,
    regional: FilterButton,
    group: FilterButton,
    state: string[],
    cluster: string[],
    subcluster: string[],
    city: string[],
    causeGroup: string[],
  }

  export interface Selected {
    year: Set<string>,
    day: Set<string>,
    month: Set<string>,
    family: Set<string>
    net: Set<string>,
    regional: Set<string>,
    group: Set<string>,
    state: string[],
    cluster: string[],
    subcluster: string[],
    city: string[],
    causeGroup: string[],
    isPead: boolean,
    isAccumulated: boolean
  }

  export interface InputParams {
    year: string,
    day: string,
    month: string,
    family: string
    net: string,
    regional: string,
    state: string,
    group: string,
    cluster: string,
    subcluster: string,
    city: string,
    causeGroup: string,
    isPead: boolean,
    isAccumulated: boolean
  }

  export interface OutputParams {
    dropdownList: Dropdown;
    selectedList: BehaviorSubject<Selected>;
    activeFilters: VandalismAnalytical.InputParams;
    tickets: any[];
    peads: any[];
  }
  export class Output {
    dropdownList: Dropdown = analyticalDropdownList;
    selectedList: BehaviorSubject<Selected> = new BehaviorSubject<Selected>(analyticalSelectedList);
    activeFilters: VandalismAnalytical.InputParams = initialStateActiveFilters;
    tickets: any[] = [];
    peads: any[] = [];
    peadsTotalElements: number;
    ticketsTotalElements: number;

    constructor(response?: OutputParams) {
      if (response) {
        this.dropdownList = response.dropdownList;
        this.activeFilters = response.activeFilters;
        this.tickets = response.tickets;
        this.peads = response.peads;
        this.selectedList = this.removePeadSelectedList(response.selectedList);  
      }
    }

    removePeadSelectedList(selectedList) {
      return new BehaviorSubject({
        ...selectedList.value,
        causeGroup: selectedList.value.causeGroup.filter((cause) => cause !== 'PEAD')
      });
    }

    formatCityName(str: string) {
      return str
        .replace(/_/g, ' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
  }
}
