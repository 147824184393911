<!-- TODO: Remover style -->
<div>
  <div 
    class="row form-group mb-3"
    style="display: flex; flex-direction: row; justify-content: space-around;"
  >
    <div class="col-lg-5 p-0">
      <!-- TODO: Estilizar a label -->
      <label class="title-label">
        Data Início
          <div class="input-group">     
            <input
              type="text"
              class="form-control"
              bsDatepicker
              #startDate="bsDatepicker"
              [bsConfig]="bsConfig"
              placeholder="dd/mm/yyyy"
              [(bsValue)]="startDateValue"
              (bsValueChange)="onChange('startDate')"
            >            
              <div class="data-picker-input-group-btn">
                <button 
                  class="data-picker-btn btn"
                  [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : getColors().secondaryColor }"
                  (click)="startDate.show()"
                  [attr.aria-expanded]="startDate.isOpen"
                  type="button"
              >
                <i class="fa fa-calendar" [ngStyle]="{ 'color': color ? color : '#fff' }"></i>
              </button>
            </div>
          </div>
      </label>
    </div>

    <div class="col-lg-5 p-0">
      <label class="title-label">
        Data Fim
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            #endDate="bsDatepicker"
            bsDatepicker
            [bsConfig]="bsConfig"
            placeholder="dd/mm/yyyy"
            [(bsValue)]="endDateValue"
            (bsValueChange)="onChange('endDate')"
            >
          <div class="data-picker-input-group-btn">
            <button 
              class="data-picker-btn btn"
              (click)="endDate.show()"
              [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : getColors().secondaryColor }"
              [attr.aria-expanded]="endDate.isOpen"
              type="button">
              <i 
                class="fa fa-calendar" [ngStyle]="{ 'color': color ? color : '#fff' }"
              ></i>
            </button>
          </div>
        </div>
      </label>
    </div>
  </div>
</div>
