import * as faSvgIcons from '@fortawesome/free-solid-svg-icons';

export const icons = {
  brush: faSvgIcons.faBrush,
  building: faSvgIcons.faBuilding,
  calendar: faSvgIcons.faCalendar,
  calendarDay: faSvgIcons.faCalendarDay,
  clock: faSvgIcons.faClock,
  closedCaptioning: faSvgIcons.faClosedCaptioning,
  globe: faSvgIcons.faGlobeAfrica,
  gopuram: faSvgIcons.faNetworkWired,
  headset: faSvgIcons.faHeadset,
  image: faSvgIcons.faImages,
  trash: faSvgIcons.faTrash,
  userCircle: faSvgIcons.faUserCircle,
  userEdit: faSvgIcons.faUserEdit,
  users: faSvgIcons.faUsers,
  user: faSvgIcons.faUser,
  timesCircle: faSvgIcons.faTimesCircle,
  undo: faSvgIcons.faUndo,
  search: faSvgIcons.faSearch,
  danger: faSvgIcons.faExclamationCircle,
  exclamationTriangle: faSvgIcons.faExclamationTriangle,
  percentage: faSvgIcons.faPercentage,
  before:faSvgIcons.faLessThan,
  after:faSvgIcons.faGreaterThan,
}
