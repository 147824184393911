import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-pop-up-row',
  templateUrl: './ticket-row-pop-up.component.html',
  styleUrls: []
})
export class PopupRowComponent implements OnInit {
  @Input() label: string;
  @Input() value: any;
  @Input() effectivenessFlag?: boolean;
  @Input() effectivenessClass?: string = '';

  constructor() {
  }

  ngOnInit(): void {}
}
