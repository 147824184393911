export interface ITicket {
  id: number,
  guid: string,
  ticketId: number,
  incidentId: string,
  taskIdIncident: string,
  ticketSystemOrigin: string,
  reportedDate: string,
  dtroute: string
  cause: string,
  state: string,
  city: string,
  lat: number,
  lng: number,
  latStart: number,
  lngStart: number,
  latEnd: number,
  lngEnd: number,
  status: string,
  sla: string,
  incidentType: string,
  productOperational1: string,
  productOperational2: string,
  cause1: string,
  cause2: string,
  address: string,
  company: string,
  technicianId: string,
  operational: string,
  operational2: string,
  operational3: string,
  directors: string,
  regional: string,
  cluster: string,
  subcluster: string,
  causeGroup: string,
  quantity: number,
}
