import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExportService } from './export.service';
import {
  VandalismGeneral,
  VandalismFullscreen,
  VandalismAlert,
  VandalismRoute,
  VandalismRoundReport,
  VandalismTicketPopup
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class VandalismService {
  private readonly baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private exportService: ExportService
  ) {
    this.baseUrl = environment.baseUrl;
  }

  private postData<TInput, TOutput>(input: TInput, endpoint: string): Observable<TOutput> {
    const url = `${this.baseUrl}/vandalism/${endpoint}`
    const output = this.httpClient.post<TOutput>(url, input);
    return output;
  }

  getVandalismGeneralData(input: VandalismGeneral.Params): Observable<VandalismGeneral.Output> {
    return this.postData(input, 'general');
  }

  getVandalismAlertData(input: VandalismAlert.Params): Observable<VandalismAlert.Output> {
    return this.postData(input, 'alert');
  }

  getVandalismRouteData(input: VandalismRoute.Params): Observable<VandalismRoute.Output> {
    return this.postData(input, 'route');
  }

  getVandalismFullscreenData(input: VandalismFullscreen.Params): Observable<VandalismFullscreen.Output> {
    return this.postData(input, 'heatMap');
  }

  getPhotosPopup({ ticketId, isFirstImage }: VandalismTicketPopup.ImagePopupParams) {
    const headers = new HttpHeaders();
    const url =`${this.baseUrl}/vandalism/popup/image/${ticketId}?isFirstImage=${isFirstImage}`

    headers.set('Accept', 'image/jpeg')
    const requestOptions = {
      headers: headers,
      redirect: 'follow'
    };

    return this.httpClient.get(url, requestOptions);
  }

  getTicketPopup({ ticketId, guid}: VandalismTicketPopup.TicketPopupParams) {
    const headers = new HttpHeaders();
    const url =`${this.baseUrl}/vandalism/popup/ticket/${ticketId}?guid=${guid}`

    headers.set('Accept', 'image/jpeg')
    const requestOptions = {
      headers: headers,
      redirect: 'follow'
    };

    return this.httpClient.get(url, requestOptions);
  }

  sendOccurrenceReport(input: VandalismTicketPopup.ReportIncidentPopupParams) {
    const url = `${this.baseUrl}/vandalism/popup/occurrence/${input.guid}?occurrence=${input.occurrence}`;
    return this.httpClient.post<{ message: string, status: number }>(url, {});
  }

  sendPhotoManualReport(input: VandalismTicketPopup.ReportImagePopupParams) {
    const url =`${this.baseUrl}/vandalism/image/validation/sharpness`
    return this.httpClient.post<{ message: string, status: number }>(url, input);
  }

  createVandalismRoute(input: { routeId: string, family: string, mapLink: string, date: string }) {
    const url = `${this.baseUrl}/vandalism/route/create`; 
    return this.httpClient.post<{ message: string, status: number }>(url, input);
  }

  generateRoundReport(input: VandalismRoundReport.Params): void {
    const url = `${this.baseUrl}/vandalism/route/excel`;    
    this.httpClient.post<VandalismRoundReport.Response>(url, input)
      .pipe(
        map((response) => {
          return response ? response.map(r => ({
            'ID da Rota': r.routeId,
            'Data': r.routeDate,
            'Início': r.origin,
            'Quantidade de Nós': r.stopPoints,
            'Final': r.destination,
            'Diretoria': r.directory,
            'Score': r.score,
          })) : [];
        })
      )
      .subscribe((data) => {
        const dateCurrent = new Date().toLocaleDateString();
        return this.exportService.exportToExcel({
          data,
          title: 'Relatório de Ronda',
          date: dateCurrent,
          filename: 'relatorio_de_ronda',
        });
      });
    }
}
