import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { VandalismAnalytical } from 'src/app/interfaces';
import { AnalyticalChart } from 'src/app/interfaces/models/IAnalyticalChart';
import { initialAnalyticalChart, initialStateActiveFilters } from 'src/app/utils/constants';
import { InverseGroupsMapped } from 'src/app/utils/mapped';

@Component({
  selector: 'app-analytical-cascate-bar',
  templateUrl: './analytical-cascate-bar.component.html',
  styleUrls: ['./analytical-cascate-bar.component.sass']
})
export class AnalyticalCascateBarComponent implements OnInit, OnChanges {

  @Input() data: AnalyticalChart = initialAnalyticalChart;
  @Input() width = 75;
  @Input() loading = true;
  @Input() error = false;
  @Output() filterClick = new EventEmitter<string>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  barWidthProportion = '100%';

  activeFilters: VandalismAnalytical.InputParams = initialStateActiveFilters;
  filterValues = VandalismAnalytical;
  key="group"
  colors: string[];
  barAnalytic = { dataset: [], labels: [], title: '' }
  type: ChartType = 'bar';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    onClick: (event, activeElements: Array<any>) => {
      if (activeElements.length > 0) {
        let firstElement = activeElements[0];
        let elementIndex = firstElement._index;
        let label = this.barAnalytic.labels[elementIndex];
        const value = InverseGroupsMapped[label];
        const filtersActiveByKey = this.activeFilters[this.key].split(',');

        if (filtersActiveByKey.includes(value)) {
          this.onChange(filtersActiveByKey.filter((v) => v !== '' && v !== value));
          return;
        }

        this.onChange(value);
      }
    },
    scales: { 
      xAxes: [{     
        ticks: {
          callback: (value: string, index, values) => {
            let newValue = value;
            newValue = newValue.replace('Regional', '');
            newValue = newValue.replace('Grupo', '');
            return newValue;
          }
        },         
        gridLines: {
          display: false,          
        },  
        stacked: true      
      }], 
      yAxes: [{        
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        }, 
      }] 
    },
    tooltips: {
      callbacks: {
        label: ({ value }) => {
          return `${Number(value).toFixed(1)}`;
        }
      }
    },
    layout: {
      padding: {
        top: 20,
        bottom: 0
      },
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        padding: {
          top: -5,
          bottom: 0
        },
        color: 'black',
        font: {
          weight: 'bold',
          size: 11 
        },
        formatter: (value, context) => {
          return `${value.toFixed(1)}%`
        }
      }
    }
  };

  constructor() {}
  
  ngOnInit() {
  }

  ngOnChanges() {
    this.createBarAnalytic(this.data);
    this.updateBarProportion(this.data.datasets);
  }

  updateChartSize() {    
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  createBarAnalytic(data: AnalyticalChart) {
    const { datasets, labels, legends, title } = data;
    const cumulativeData = [];
    let cumulativeSum = 0;
    if (datasets.length === 0) return this.barAnalytic;
    const treatedDataset = this.getTreatedDatasets();

    treatedDataset.forEach((value) => {
      cumulativeSum += value;
      cumulativeData.push(cumulativeSum);
    });

    const barColor = treatedDataset.map(this.getBarColorByValue);

    this.barAnalytic = {
      dataset: [{
        label: `PROJEÇÃO ${legends}`,
        data: treatedDataset,
        backgroundColor: barColor,
        datalabels: {
          align: (context) => {
            return context.dataset.data[context.dataIndex] >= 0 ? 'start' : 'end';
          },
          anchor: (context) => {
            return context.dataset.data[context.dataIndex] >= 0 ? 'start' : 'end';
          },
          padding: {
            top: -5,
            bottom: 0
          },
          color: barColor,
          font: {
            weight: 'bold',
            size: 11
          },
          formatter: (value, context) => {
            return `${value}%`
          }
        }
      }],
      labels,
      title
    };
  };

  updateBarProportion(datasets) {
    const datasetLength = datasets.reduce((acc, current) => current.length + acc, 0) || 0;
    const control = 18
    const newWidth = (datasetLength / control) * this.width
    this.barWidthProportion = `${newWidth}%`
    this.updateChartSize();
  }

  getTreatedDatasets() {
    return this.data.datasets.reduce((acc, curr) => [...acc, curr[0][0]], []);
  }

  getBarColorByValue = (value: number) => {
    return value >= 0 ? '#FF0000' : '#00FF00'
  }
  
  onChange(labelIndex: string) {      
    this.filterClick.emit(`${this.key}:${labelIndex}`);    
  }
}
