import { AgmMap, ControlPosition, LatLngBounds, MapTypeStyle, Marker } from '@agm/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ITicket, VandalismAnalytical } from 'src/app/interfaces';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { AnalyticalService } from 'src/app/services/analytical.service';
import { TicketPopUpComponent } from 'src/app/maps/ticket-pop-up/ticket-pop-up.component';
import { initialStateActiveFilters } from 'src/app/utils/constants';
import { LegendItem } from 'src/app/interfaces/models/ILegendItem';
import { analyticalDefaultLegend, getDateUtils, getDefaultFilters } from 'src/app/utils/interfaces/constants';
import { AnalyticalLegend } from 'src/app/interfaces/enums/AnalyticalLegend';
import { mapCauseOption } from 'src/app/utils/mappers/causeOptions';
import { CauseOption } from 'src/app/interfaces/enums/CauseOption';
import { icons } from 'src/app/utils/icons';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const { currentMonth, currentYear } = getDateUtils();

export const defaultActiveFilters = getDefaultFilters({
  month: currentMonth,
  year: currentYear,
  causeGroup: `${CauseOption.VANDALISMO},${CauseOption.PEAD}`
});

export interface MarkerLocation {
  guid: string,
  ticketId: number,
  lat: number,
  lng: number,
  reportedDate: string,
  operational: string,
  icon: string,
  cause: string,
  incident: string
}

export interface ConnectionLocation {
  lat: number,
  lng: number,
  latStart: number,
  lngStart: number,
  latEnd: number,
  lngEnd: number
}

@Component({
  selector: 'app-analytical-fullscreen-map',
  templateUrl: './analytical-fullscreen-map.component.html',
  styleUrls: [],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })),
      state('out', style({
        'max-height': '0',
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('in => out', [animate('800ms ease-in-out')]),
      transition('out => in', [animate('800ms ease-in-out')])
    ])
  ]
})
export class AnalyticalFullscreenMapComponent implements OnInit {

  filters: any;
  centerPosition: { lat: number, lng: number };

  private static ANALITICO_URL = '/vandalismo/analitico';

  @ViewChild(AgmMap) agmMap: AgmMap;
  map: google.maps.Map;

  NAMESPACE: Namespace = 'analyticalFullscreen';
  icons=icons

  data: BehaviorSubject<VandalismAnalytical.Output> = new BehaviorSubject(new VandalismAnalytical.Output());
  loading: boolean = true;
  error: boolean = false;
  activeFilters: VandalismAnalytical.InputParams = defaultActiveFilters;  
  undoDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  tickets: BehaviorSubject<MarkerLocation[]> = new BehaviorSubject([]);
  peads: BehaviorSubject<ConnectionLocation[]> = new BehaviorSubject([]);
  filteredMarkers: BehaviorSubject<MarkerLocation[]> = new BehaviorSubject([]);
  activeYears: string[] = [];
  historyActiveFilters: Array<VandalismAnalytical.InputParams> = [];
  searchControl = new FormControl();  
  searchInput = new Subject<string>();
  activitiesNumber: number = 0;
  peadsNumber: number = 0;
  legends: BehaviorSubject<LegendItem<AnalyticalLegend>[]> = new BehaviorSubject(analyticalDefaultLegend);
  isPeadsActivated: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isAccumulatedPeadsActivated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isPeadsSuggestion: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDivVisible: boolean = false;
  isAnalytic: boolean = true;
  isReset: boolean = false;
  isSearchVisible: boolean = false;
  slideConfig = {
    slidesToShow: 1,
    dots: true,
  };

  mapConfig = {
    zoom: 4, // Google Maps zoom
    maxZoom: 19,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUI: false,
    optimized: false,
    
    iconRedUrl: '/assets/maps/red-circle.svg',
    iconBlackUrl: '/assets/maps/black-circle.svg',
    iconPolygonGreenUrl: '/assets/maps/polygon-light-green.svg',
    iconPolygonLightGreenUrl: '/assets/maps/polygon-light-green.svg',
    center: { // Posição inicial do mapa no centro do Brasil.
      lat: -15.8517, // Latitude
      lng: -48.5799 // Longitude
    },
    mapTypeControl: false,
    styles: <MapTypeStyle[]>[ // Define como MapTypeStyle[]
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ visibility: 'simplified' }]
    }
  ]
  }

  constructor(
    private activeModal: NgbActiveModal,
    private filterService: FilterService,
    private analyticalService: AnalyticalService,
    private modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) {    

    this.filterService.setDefaultFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.updateData(activeFilters)
      });
  }

  ngOnInit(): void {        
    // const filters = this.filterService.getFilters();

    // const keyMapping = {
    //   causeGroup: 'causeGroup',
    //   cities: 'city',
    //   clusters: 'cluster',
    //   directors: 'director',
    //   regionals: 'regional',
    //   states: 'state',
    //   subclusters: 'subcluster',
    //   suggestion: 'suggestion',
    //   year: 'year',
    //   month: 'month',
    //   day: 'day',
    //   family: 'family',
    //   group: 'group',
    //   net: 'net',
    //   isAccumulated: 'isAccumulated',
    //   isPead: 'isPead'
    // };
  
    // const mappedFilters = Object.keys(filters).reduce((acc, key) => {
    //   const mappedKey = keyMapping[key] || key;
    //   acc[mappedKey] = filters[key];
    //   return acc;
    // }, {} as VandalismAnalytical.InputParams);
  
    // this.activeFilters = { ...this.activeFilters, ...mappedFilters };
  
    // // Verifique se `activeFilters` foi atualizado corretamente
    // console.log('Active Filters Após Mapeamento:', this.activeFilters);
  
    // // Aplique o filtro no mapa usando `applyFiltersToMap` após a atualização
    // this.applyFiltersToMap(this.activeFilters);
    
    
    this.tickets.subscribe(data => {
      this.filteredMarkers.next(data);  
    });

    this.searchInput.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    ).subscribe((value: string) => { 
      this.onSearch(value)
    });

    this.analyticalService
      .isLoaded
      .asObservable()
      .subscribe((isLoaded) => {
        if (isLoaded) this.updateData(defaultActiveFilters);
      });

      // this.route.queryParams.subscribe(params => {
      //   const lat = params['lat'] ? +params['lat'] : null;
      //   const lng = params['lng'] ? +params['lng'] : null;
    
      //   if (lat !== null && lng !== null) {
      //     this.setMapCenter({ lat, lng });
      //   } else {          
      //   }
      // });
  }

  // applyFiltersToMap(filters: VandalismAnalytical.InputParams): void {    
  //   if (filters.city) {      
  //     console.log('Aplicando filtro de cidade:', filters.city);
  //   }
  // }

  // // ASETAR CENTRAL DO MAPA
  // setMapCenter(position: { lat: number, lng: number }) {    
  //   if(position)
  //   {      
  //     this.mapConfig.center.lat = position.lat;
  //     this.mapConfig.center.lng = position.lng;
  //     this.mapConfig.zoom = 22;
  //   }    
  // }

  updateData(activeFilters: VandalismAnalytical.InputParams) {
    this.activeFilters = activeFilters;
    this.activeYears = activeFilters.year.split(',');
    const filteredTickets = this.analyticalService.filterTickets(activeFilters);
    const { dropdownList, selectedList } = this.analyticalService.getFilters(activeFilters, filteredTickets);

    this.getLocations();
    
    this.data.next(new VandalismAnalytical.Output({
      dropdownList: dropdownList,
      selectedList: selectedList,
      activeFilters: activeFilters,
      tickets: [],
      peads: []
    }));
  }

  getLocations() {
    this.loading = true;
    this.error = false;
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}/vandalism/analytical/locations`;
    const body = {
      "years": this.activeFilters.year,
      "months": this.activeFilters.month,
      "days": this.activeFilters.day,
      "causes": this.activeFilters.causeGroup,
      "regionals": this.activeFilters.regional,
      "directors": this.activeFilters.group,
      "states": this.activeFilters.state,
      "subClusters": this.activeFilters.subcluster,
      "clusters": this.activeFilters.cluster,
      "cities": this.activeFilters.city,
      "families": this.activeFilters.family,
      "nets": this.activeFilters.net,
      "isPead": this.isPeadsActivated.value,
      "isAccumulated": this.isAccumulatedPeadsActivated.value,
      "isSuggestion": this.isPeadsSuggestion.value
    }

    // console.log('Enviando body da requisição com filtros:', body);

    return this.http
      .post<{ tickets: [], peads: [] }>(url, body)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        this.activitiesNumber = response.totalTickets;
        this.peadsNumber = response.totalPeads;
        this.peads.next(response.peads);
        this.tickets.next(response.tickets.filter(this.isValidLocations));
      });
  }

  isValidLocations({ lat, lng }) {
    const invalidValues = [null, undefined, 0];
    return !invalidValues.includes(lat) && !invalidValues.includes(lng);
  }

  updateMarkers(newBounds: LatLngBounds) {
    this.filteredMarkers.next(this.tickets.value.filter((marker) => {
      const lat = marker.lat;
      const lng = marker.lng;
      let markerLatLng = new google.maps.LatLng(lat, lng);
      return newBounds.contains(markerLatLng);
    }));
  }

  openTicketPopUp(ticket: ITicket) {
    if (ticket["cause"] === "SUGESTÃO PEADS") return;
    const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
    modalRef.componentInstance.modalData = ticket;
  }

  createCustomWindowed() {
    const svgImage = document.createElement("img");
    svgImage.src = "assets/maps/fullscreen_desativado.svg";
    svgImage.width = 22;
    svgImage.height = 22;
    const controlButton = document.createElement("button");
    controlButton.type = "button"
    controlButton.title = "Desativar o fullscreen do mapa";
    controlButton.setAttribute('class', 'control-button');
    controlButton.appendChild(svgImage);
    controlButton.addEventListener('click', () => this.closeModal());
    return controlButton;
  }
  
  onMapReady(map: google.maps.Map) {
    const customControlDiv = document.createElement('div');
    const customFullScreenControl = this.createCustomWindowed();
    customControlDiv.appendChild(customFullScreenControl);
    map.controls[ControlPosition.BOTTOM_RIGHT].push(customControlDiv)

    map.setOptions({
      styles: this.mapConfig.styles,
      mapTypeControl: this.mapConfig.mapTypeControl
    });

    this.map = map;
  }

  treatTooltip(marker: MarkerLocation) {
    const id = marker.ticketId ? `ID: ${marker.ticketId}` : '';
    const date = marker.reportedDate ? `Data: ${marker.reportedDate}` : '';
    const operation = marker.operational ? `Operação: ${marker.operational}` : '';
    return [id, date, operation].filter(Boolean).join('\n');
  }

  closeModal() {
    this.activeModal.close();
    this.router.navigate([AnalyticalFullscreenMapComponent.ANALITICO_URL])
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;    
  }

  iconUrl(icon: string) {
    return `/assets/maps/${icon}.svg`;
  }

  isResidencial(family: string) {
    return family.toUpperCase() === 'RESIDENCIAL';
  }

  resetFilters() {
    const center = new google.maps.LatLng(this.mapConfig.center.lat, this.mapConfig.center.lat);
    this.map.setCenter(center);
    this.map.setZoom(this.mapConfig.zoom);
    this.filterService.updateMultipleFilters({
      namespace: this.NAMESPACE,
      newFilters: initialStateActiveFilters,
    });
  }
  
  toggleSearchButton() {
    this.isSearchVisible = !this.isSearchVisible;
  }

  onSearch(value: string): void {
    this.loading = true;
     
    const conditionForSearch = value !== '' && value.length > 5
    if (conditionForSearch) {
      const response = this.analyticalService.getTicketsById(value);
      response.subscribe((data: any) => {
        try {
          this.loading = false;
          const toMap = (ticket: ITicket): MarkerLocation => {
            return {
              guid: ticket.guid,
              ticketId: ticket.ticketId,
              lat: ticket.lat,
              lng: ticket.lng,
              reportedDate: ticket.reportedDate,
              operational: ticket.operational,
              icon: ticket.causeGroup === 'PEAD' ? (ticket.productOperational1) === 'RESIDENCIAL' ? 'polygon-light-green' : 'polygon-green' : (ticket.productOperational1 === 'RESIDENCIAL' ? 'red-circle' : 'black-circle'),
              incident: ticket.incidentType,
              cause: ticket.cause
            }
          }
          const mappedTicket: MarkerLocation[] = data.map(toMap);
          this.tickets.next(mappedTicket);
        } catch (e) {
          this.loading = false;
          return;
        }
      });
    } else {
      this.filterService.actionChange({ namespace: this.NAMESPACE });
    }
  }

  onUndo(): void {
    const prevValue = this.historyActiveFilters.pop();
    if (prevValue !== undefined) {
      this.filterService.updateMultipleFilters<VandalismAnalytical.InputParams>({
        namespace: this.NAMESPACE,
        newFilters: prevValue
      });
      const isLastElement: boolean = this.historyActiveFilters.length === 0;
      this.undoDisabled.next(isLastElement);
      this.toggleActivatePeads(prevValue.causeGroup.includes('PEAD'));
    }
  }

  onReset() {
    this.toggleActivatePeads(defaultActiveFilters.causeGroup.includes('PEAD'));
    this.filterService.updateMultipleFilters({
      namespace: this.NAMESPACE,
      newFilters: defaultActiveFilters,
    });
  }

  onVandalism() {
    this.onChange('causeGroup:VANDALISMO');
    this.onActivatePeadsChange(false);
    this.onAccumulatedPeadsChange(false);
  }

  onActivatePeadsChange(isActivated: boolean) {
    this.toggleActivatePeads(isActivated);
  }

  onAccumulatedPeadsChange(isActivated: boolean) {
    this.toggleAccumulatedPeads(isActivated);
  }

  onSuggestedPeadsChange(isActivated: boolean) {
    this.toggleSuggestionPeads(isActivated);
  }

  toggleSuggestionPeads(shouldActivate: boolean) {
    const isActive = shouldActivate !== undefined ? shouldActivate : !this.isPeadsSuggestion.value;
    this.isPeadsSuggestion.next(isActive && this.isPeadsActivated.value);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  }

  toggleAccumulatedPeads(shouldActivate: boolean) {
    const isActive = shouldActivate !== undefined ? shouldActivate : !this.isAccumulatedPeadsActivated.value;
    this.isAccumulatedPeadsActivated.next(isActive && this.isPeadsActivated.value);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  }

  toggleActivatePeads(shouldActivate: boolean) {

    const isActive = shouldActivate !== undefined ? shouldActivate : !this.isPeadsActivated.value;

    const updateLegendVisibility = (legend: LegendItem<AnalyticalLegend>, isActive: boolean): LegendItem<AnalyticalLegend> => {
        return { ...legend, isVisible: isActive };
    };

    const isPeadType = (legend: LegendItem<AnalyticalLegend>) => legend.name.includes(CauseOption.PEAD)

    const updatedLegends = this.legends.value.map((legend) => 
      isPeadType(legend) ? updateLegendVisibility(legend, isActive) : legend
    );

    this.legends.next(updatedLegends);

    const activateCausesWithoutPead = this.activeFilters.causeGroup
      .split(',')
      .filter((cause) => cause !== 'PEAD' && cause !== '')
      .join(',')

    this.isPeadsActivated.next(isActive);

    if (!isActive && (this.isAccumulatedPeadsActivated.value || this.isPeadsSuggestion.value)) {
      this.toggleAccumulatedPeads(false);
      this.toggleSuggestionPeads(false);
    }
  
    this.onChange(`causeGroup:${activateCausesWithoutPead}${isActive ? ',PEAD' : ''}`);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  }

  onChange(event: string) {
    const [key, values] = event.split(':');

    let newActiveFilters = this.activeFilters;
    let newValues = values;

    if (key === "causeGroup") {
      const notNull = (value) => value !== null;
      const causes = values.split(',').map(mapCauseOption).filter(notNull);
          
      if (!values.includes('PEAD') && !causes.includes(CauseOption.PEAD) && this.isPeadsActivated.value) {
        const valuesArr = values !== '' ? values.split(',') : [];
        causes.push(CauseOption.PEAD);
        valuesArr.push('PEAD');
        newValues = valuesArr.length < 1 ? valuesArr.join('') : valuesArr.join(',');
      };
    };

    
    this.historyActiveFilters.push(newActiveFilters);
    const isLastElement: boolean = this.historyActiveFilters.length === 0;
    this.undoDisabled.next(isLastElement);
    
    this.filterService.updateFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      key,
      values: newValues
    });
    // console.log(newActiveFilters);
  }

  ngOnDestroy() {
    this.data.unsubscribe();
    this.peads.unsubscribe();
    this.tickets.unsubscribe();
    this.filteredMarkers.unsubscribe();
    this.undoDisabled.unsubscribe();
    this.searchInput.unsubscribe();
    this.legends.unsubscribe();
    this.isPeadsActivated.unsubscribe();
    this.isAccumulatedPeadsActivated.unsubscribe();
    this.isPeadsSuggestion.unsubscribe();
    this.isPeadsSuggestion.unsubscribe();
    this.legends.unsubscribe();
  }
}
