import { ImageVerification, Legendverification } from "src/app/pages/images/verificacao-images/verificacao-images.component";
import { causeGroups, regionals, groups } from "src/app/utils/constants";

// TODO: Colocar em interfaces
export enum VerificationOption {
  SUCCESS='isSuccess',
  FAILED='isFailure',
  GPS='isSuccessGps',
  SIZE='isSuccessSize',
  DATE='isSuccessDate',
  DIMENSIONS='isSuccessDimensions',
  PIXEL='isSuccessAmountPixel',
  DUPLICATE='isSuccessDuplicate',
  META="isSuccessMeta",
  SHARPNESS='isSuccessSharpness'
}

export const legends = [
  { description: "Download Sucesso", value: 'SUCCESS' },
  { description: "Arquivo Dimensões Pequenas", value: 'DIMENSIONS' },
  { description: "Arquivo Tamanho Pequeno", value: 'SIZE' },
  { description: "Duplicados", value: 'DUPLICATE' },
  { description: "Data Incorreta", value: 'DATE' },
  { description: "Imagem Falhou", value: 'FAILED' },
  { description: "GPS Incorreto", value: 'GPS' },
  { description: "Nitidez Baixa", value: 'SHARPNESS' },
  { description: "Pixels brancos/pretos", value: 'PIXEL' },
  { description: "Sem Metadados", value: 'META' }
];

export const validations = legends
  .filter(({ value }) => value !== 'SUCCESS' && value !== 'FAILED')
  .map(({ description }) => description)

export const initialDataImageVerification = {
  filters: {
    dropdown: {
      causes: causeGroups,
      validations: validations,
      regionals: regionals,
      directors: groups
    },
    selected: {
      causes: [],
      validations: [],
      regionals: [],
      directors: []
    }
  },
  totalImages: 0,
  totalTickets: 0,
  legends: [],
  headers: [],
  body: []
}

export namespace ImageVerificationNsp {

  export interface Filters {
    causes: string[],
    validations: string[],
    regionals: string[],
    directors: string[],
  }

  export interface Params {
    startDate: string,
    endDate: string,
    causes: string,
    validations: string,
    regionals: string,
    directors: string,
  }

  export interface Response {
    filters: {
      dropdown: Record<string, string[]>,
      selected: Record<string, string[]>
    }
    totalTickets: number,
    totalImages: number,
    legends: Legendverification[],
    headers: string[],
    body: ImageVerification[]
  }
  
  export class Output {
    data: Response = initialDataImageVerification;
  
    constructor(response?: Response) {
      if (response) {
        this.data = {
          filters: response.filters,
          headers: response.headers,
          body: response.body,
          totalTickets: response.totalTickets,
          totalImages: response.totalImages,
          legends: legends,
        };
      }
    }
  }
}