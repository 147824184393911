
<div *ngIf="loading.value" class="loading-screen">
  <div class="loading-animation">
    <div class="spinner-container">        
      <div class="spinner"></div>
    </div>
    <p class="loading-title">Carregando...</p>
  </div>
</div>

<div class="modal-header">
  <h5 class="modal-title">Gerar relatório de desempenho dos técnicos</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('cancel')"
    [disabled]="isLoading"
  >
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="reportForm" [class.disabled]="isLoading">
    <div class="mb-3">
      <label for="companies" class="form-label">Empresas</label>
      <ng-select
        [items]="companyOptions"
        bindLabel="description"
        bindValue="value"
        formControlName="companies"
        [multiple]="true"
        [disabled]="isLoading"
        (change)="onCompanySelected($event)">
      </ng-select>
    </div>
    
    <div class="mb-3">
      <label for="technicians" class="form-label">Técnicos</label>
      <ng-select
        [items]="technicianOptions"
        bindLabel="description"
        bindValue="value"
        formControlName="technicians"
        [multiple]="true"
        [disabled]="isLoading"
        (change)="onTechnicianSelected($event)">
      </ng-select>
    </div>
    
    <div class="mb-3">
      <label for="fileTypes" class="form-label">Tipo de arquivo</label>
      <ng-select
        [items]="optionsFiles"
        bindLabel="description"
        bindValue="value"
        formControlName="fileTypes"
        [multiple]="false"
        [disabled]="isLoading">
      </ng-select>
    </div>
    
    <div class="mb-3">
      <label for="validations" class="form-label">Validações</label>
      <ng-select
        [items]="optionsLegends"
        bindLabel="description"
        bindValue="value"
        formControlName="validations"
        [multiple]="true"
        [disabled]="isLoading">
      </ng-select>
    </div>
    
    <div class="mb-3" *ngIf="!isTypeValidation()">
      <label for="technicianCount" class="form-label">Quantidade de Técnicos</label>
      <ng-select
        [items]="optionsTechnicianCount"
        bindLabel="value"
        bindValue="value"
        formControlName="technicianCount"
        [multiple]="false">
      </ng-select>
    </div>
    
  </form>

  <!-- Loading Overlay -->
  <div *ngIf="isLoading" class="loading-overlay">
    <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Carregando...</span>
      </div>
      <p class="mt-2">Gerando, por favor aguarde...</p>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-primary"
    [disabled]="reportForm.invalid || isLoading"
    (click)="sendTechnicianReportEmail()"
  >
    Gerar relatório
  </button>
</div>
