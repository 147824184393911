<div class="h-100 w-100">
  <div class="container-login">
    <div class="d-none d-lg-block col-lg-6">
      <div class="d-logo"> 
        <img src="../../../../assets/images/logo-1.png" alt="">       
      </div>
    </div> <!-- logo desktop -->
    <div class="login-content h-100 col-12 col-md-12 col-lg-6">
      <div class="col-12 col-lg-12"> 
        <div class="d-block d-sm-block d-lg-none col-12">
          <div class="m-logo"> 
            <img src="../../../../assets/images/logo-2.png" alt="">       
          </div>
        </div> <!-- logo mobile -->         
        <div class="login-h1 d-none d-md-flex d-xl-flex">
          <h1>Bem-Vindo</h1>
          <h4>Conecte-se a sua conta</h4>
        </div>        
        <div class="form-default col-8 col-md-5 col-xxl-5 mx-auto">
          <form method="post" [formGroup]="form" (submit)="onSubmit($event)">
            <div class="input-default">
              <div class="input-icon-default lnr-user"></div>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="E-mail"
                class="form-control"
                formControlName="email"
              >
            </div>
            <div class="alert" *ngIf="form.get('email')?.errors && form.get('email')?.touched">
              <i class="fa fa-times-circle"></i> E-mail é obrigatório
            </div>
            <div class="input-default">
              <div class="input-icon-default lnr-lock"></div>
              <input
                id="password"
                name="password"
                [type]="showPassword ? 'text' : 'password'"
                [(ngModel)]="password"
                (ngModelChange)="onInputChange($event)"
                placeholder="Senha"
                class="form-control"
                formControlName="password"
              >
              <i 
                *ngIf="password"
                class="eye-position"
                [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'" (click)="toggleShowPassword()"
              >
              </i>
            </div>
            <p class="alert" *ngIf="form.get('password')?.errors && form.get('password')?.touched">
              <i class="fa fa-times-circle"></i> Senha é obrigatória
            </p>
            <div class="input-default">
              <div class="input-icon-default lnr-keyboard"></div>
              <input
                id="companyCode"
                name="companyCode"
                type="number"
                placeholder="Código"
                class="form-control"
                formControlName="companyCode"
              >
            </div>
            <div class="alert" *ngIf="form.get('companyCode')?.errors && form.get('companyCode')?.touched">
              <i class="fa fa-times-circle"></i> Código é obrigatório
            </div>
            <div class="link" style="display: flex; justify-content: center;">
              <a routerLink="/forgot-password">Esqueceu sua senha?</a>
            </div>
            <div style="display: flex; align-items: center;">
              <button type="submit" class="btn-default mx-auto col-lg-6 col-md-6 col-6">
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div> <!-- div inputs -->
    </div> <!-- login content -->     
  </div> <!-- container login --> 
</div>