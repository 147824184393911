<div class="container-access-control-directory">

  <!-- CONTAINER FILTERS -->
  <div class="d-flex">
    <div class="col-lg-4 col-md-4">
      <app-data-picker-default
        [dates]="getRangeDate()"
        (event)="onChange($event)"
      >
      </app-data-picker-default>
    </div>

    <div class="col-lg-2 col-md-2">
      <app-select-default
        key="operations"
        title="Operações"
        [dropdownList]="this.data.value.filters.operations"
        [selectedItems]="['login']"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>    

    <div *ngIf="isAdmin()" class="col-lg-2 col-md-2">
      <app-select-default
        key="companies"
        title="Empresas"
        [dropdownList]="this.data.value.filters.companies"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>
  </div>


  <div class="row">
    <div class="card-chart chart-full col-12 col-md-12 col-xxl-12 chart-height">
      <div class="card-chart-header chart-full-header">
        <p class="chart-title">Utilização por Diretoria (%)</p>
      </div>
      <hr>
  
      <!-- Verifica se está carregando -->
      <div *ngIf="loading" class="loading-container d-flex flex-column justify-content-center align-items-center expand-max">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Carregando...</span>
        </div>
        <p class="loading-text mt-3">Estamos carregando os dados. Por favor, aguarde.</p>
      </div>

      <!-- Verifica se houve erro na requisição -->
      <div *ngIf="error && !loading" class="error-container d-flex flex-column justify-content-center align-items-center expand-max">
        <fa-icon [icon]="icons.danger" class="text-danger" size="3x"></fa-icon>
        <p class="error-text mt-3">Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.</p>
      </div>

      <div *ngIf="!loading && !error" class="expand-max">
        <div class="legend-container">
          <span class="legend-dot" [ngStyle]="{'background-color': colors[0] }"></span>
          <span class="legend-text">Percentual de acesso</span>
        </div>

        <div *ngIf="!loading && !error" class="expand-max">  
          <div style="height: 100%" [ngStyle]="isLessThan100  ? {'display': 'flex', 'align-items': 'center', 'justify-content': 'center'} : {}">
            <div class="d-flex chart-container" [ngStyle]="{ 'width': barWidthProportion }">
              <canvas
                baseChart
                id="myChart"
                height="100"
                width="80"
                [datasets]="barChartData.datasets"
                [labels]="barChartData.labels"
                [options]="options"
                [legend]="false"
                [chartType]="'bar'"
              >
              </canvas>
            </div>
          </div> 
        </div>
      </div>

      <!-- Tabela de Informações com Estilos Aplicados -->
      <div style="margin-top: 3vh;">
        <table class="image-verification-table">
          <thead>
            <tr class="image-verification-header">
              <th>Informações de Acesso</th>
              <th *ngFor="let label of barChartLabelTreat()">{{ label }}</th>
            </tr>
          </thead>
          
          <tbody>
            <!-- Linha para Quantidade de Acessos -->
            <tr class="image-verification-row">
              <td class="image-verification-body">Quantidade de Acessos	</td>
              <td *ngFor="let data of this.data.value.data.datasets" class="image-verification-body">{{ data[0] }}</td>
            </tr>
            
            <tr class="image-verification-row">
              <td class="image-verification-body">Índice de Frequência</td>
              <td *ngFor="let data of this.data.value.data.datasets" class="image-verification-body">{{ data[2]?.toFixed(2) || 0 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</div>
