import { AgmMap, MapsAPILoader } from '@agm/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { mapConfigStyle, mapConfig } from 'src/app/maps/config/map-config';
import { ActivityFilters, DropdownFilters, family, states } from 'src/app/services/operations.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { icons } from 'src/app/utils/icons';
import { inventoryIcons, technicalIcons } from 'src/app/utils/map-icons';
import { InventoryService } from 'src/app/services/inventory.service';
import { ActivityInventory } from 'src/app/interfaces/models/IInventory';
import { SafeUrl } from '@angular/platform-browser';
import { fadeInOutAnimation, slideInOutAnimation } from 'src/app/animations/animations';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { IDisplayItem } from 'src/app/interfaces/models/IDisplayItem';

const defaultActiveFilters: ActivityFilters = {
  family: 'EMPRESARIAL',
  state: '',
  activityId: ''
};

@Component({
  selector: 'app-mapa-inventario',
  templateUrl: './mapa-inventario.component.html',
  styleUrls: [],
  animations: [ slideInOutAnimation, fadeInOutAnimation ]
})
export class MapaInventarioComponent implements OnInit {  

  private static INVENTARIO_URL = '/vandalismo/analitico';
  NAMESPACE: Namespace = 'inventoryFullscreen';
  
  inventoryActivities: BehaviorSubject<ActivityInventory[]> = new BehaviorSubject([]);
  activeFilters: BehaviorSubject<ActivityFilters> = new BehaviorSubject(defaultActiveFilters);
  dropdownFilters: BehaviorSubject<DropdownFilters> = new BehaviorSubject({
    family: [],
    net: [],
    states: []
  });  


  // PROPRIEDADES MAPA
  mapConfig = mapConfig;
  mapStyles = mapConfigStyle;

  // PROPRIEDADES GOOGLE MAPS
  directionsDisplay: google.maps.DirectionsRenderer;
  activitieDirectionRender: google.maps.DirectionsRenderer;
  historyDirectionRender: google.maps.DirectionsRenderer;
  directionsService: any;
  directionsRenderer: any;
  customMarkers: google.maps.Marker[] = [];
  routeMarkers: google.maps.Marker[] = [];
  infoWindow: google.maps.InfoWindow;  

  displayData: IDisplayItem[] = [];
  photos: SafeUrl[] = [];

  // PROPRIEDADES HEADER FILTER
  isDivVisible: boolean = false;
  iconBackHome = '/assets/icons/back-home.png';
  iconBackHomeWhite = '/assets/icons/back-home-white.png';

  
  loading = true;
  isLoading = true;
  initialLoad: boolean = true;
  imagesLoaded = 0;
  isZoomImage: boolean = false;

  icons = icons;
  mapIcons = inventoryIcons;    
  technicianIcons = technicalIcons;    

  // PROPRIEDADES TOOLTIP 
  technicianPosition: { lat: number, lng: number };
  isTechnician:boolean = false;
  selectedActivityId: number | null = null;
  markers: { lat: number, lng: number, activityInventory?: ActivityInventory }[] = [];
  mapCenter: { lat: number, lng: number } = { lat: -23.5505199, lng: -46.6333094 };
  mapZoom: number = 4;
  photosInventory: string[] = [];
  isModalOpen: boolean = false;
  selectedImage: string = '';
  showInfoWindow: boolean[] = [];
  showInfoWindowInventory: boolean[] = [];
  currentTooltipIndex: number = null;
  currentAssistanceTooltipIndex: number = null;
  inventoryPoints: any;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000
  };  

  @ViewChild(AgmMap) agmMap: AgmMap;
  map: google.maps.Map;
  searchTerm: string = '';  

  onImageLoad(): void {
    this.imagesLoaded++;
    if (this.imagesLoaded === this.photosInventory.length) {
      this.isLoading = false;
      // console.log(this.isLoading);
    }
  }

  constructor(
    private inventoryService: InventoryService,
    private activeModal: NgbModal,
    private router: Router,
    private filterService: FilterService,
    private mapsAPILoader: MapsAPILoader,
    private pdfService: PdfGeneratorService,    
  ) {
    this.dropdownFilters.next({ family, states, net: [] });
  
    this.filterService.setDefaultFilters<ActivityFilters>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.inventoryService.ActivitiesInventory.subscribe((result: ActivityInventory[]) => {
      this.inventoryActivities.next(result);   
    });    

    this.onImageLoad();

    this.inventoryService.isLoaded.asObservable().subscribe((isLoaded) => {
      this.isLoading = !isLoaded;
      if (isLoaded && this.initialLoad) {
        this.initialLoad = false; // Definir initialLoad como false após o primeiro carregamento
      }
    });
  }

  ngOnInit(): void {

    this.inventoryService.ActivitiesInventory.subscribe((activities) => {
      this.inventoryPoints = activities;      
    }); 

    this.isLoading = true;
    this.imagesLoaded = 0;

    this.mapsAPILoader.load().then(() => {
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
      });
  
      this.historyDirectionRender = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#F44E3F',
          strokeOpacity: 1.0,
          strokeWeight: 5
        }
      });
  
      this.agmMap.mapReady.subscribe(map => {
        this.directionsRenderer.setMap(map);
        if (this.activitieDirectionRender) {
          this.activitieDirectionRender.setMap(map);
        }
        this.historyDirectionRender.setMap(map);
        google.maps.event.addListener(this.directionsRenderer, 'directions_changed', () => { });
      });
    });
  }

  selectActivityInventory(activityInventory: ActivityInventory, index: number, resourceId: string) {
    this.selectedActivityId = activityInventory.activityId;
    // this.loadPossition(resourceId);
    
    this.inventoryService.getInventory(activityInventory.activityId).subscribe((inventoryPoints) => {
      this.inventoryPoints = inventoryPoints; 
      if (inventoryPoints && inventoryPoints.length > 0) {
        const firstRelatedObject = inventoryPoints[0];
        const lat1 = firstRelatedObject.xirEvidenciaId?.imageMetadata?.latitude;
        const lng1 = firstRelatedObject.xirEvidenciaId?.imageMetadata?.longitude;        
        const secondCoordinates = this.inventoryService.extractLatLng(activityInventory.xirGeoCode);
        if (lat1 && lng1 && secondCoordinates) {
          const centerLat = (parseFloat(lat1) + secondCoordinates.lat) / 2;
          const centerLng = (parseFloat(lng1) + secondCoordinates.lng) / 2;
    
          this.markers = [{
            lat: centerLat,
            lng: centerLng,            
            activityInventory: activityInventory
          }];
          
          this.mapCenter = { lat: centerLat, lng: centerLng };
          this.mapZoom = 24;
        }
      }
  
      this.loadImagesForActivity(activityInventory.activityId, inventoryPoints, this.showTooltipActivity.bind(this), index);
    });
  }
  

  private loadImagesForActivity(activityInventoryId: number, inventoryPoints: any[], onImagesLoaded: (index: number) => void, index: number): void {
    this.inventoryService.getImageUrlsByActivityId(activityInventoryId, inventoryPoints).subscribe((imageUrls: string[]) => {
      this.photosInventory = [];
  
      let loadedImages = 0;
  
      if (imageUrls.length === 0) {
        // Se não houver URLs, finalize o loading imediatamente
        this.loading = false;
        this.photosInventory.push('/assets/maps/svg-mapa-inventario/no-image.jpeg');
        onImagesLoaded(index);
        return;
      }
  
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
  
        img.onload = () => {
          loadedImages++;
          this.photosInventory.push(url);
  
          if (loadedImages === imageUrls.length) {
            this.loading = false; // Finaliza o loading quando todas as imagens forem carregadas
            onImagesLoaded(index);
          }
        };
  
        img.onerror = () => {
          loadedImages++;
          this.photosInventory.push('/assets/maps/svg-mapa-inventario/no-image.jpeg'); // Imagem de fallback
  
          if (loadedImages === imageUrls.length) {
            this.loading = false; // Finaliza o loading mesmo se houver falha no carregamento de alguma imagem
            onImagesLoaded(index);
          }
        };
      });
    }, error => {
      console.error('Erro ao carregar URLs de imagens:', error);
      this.loading = false;
      this.photosInventory.push('/assets/maps/svg-mapa-inventario/no-image.jpeg');
      onImagesLoaded(index);
    });
  }

  async generatePdf(activityInventory: ActivityInventory, index: number) {
    // console.log('Activity Inventory:', activityInventory);
    // Primeiro, mapeie os dados da atividade para o formato esperado
    this.inventoryDisplayData(activityInventory);
  
    if (this.displayData && this.displayData.length > 0) {
      const ref = activityInventory.activityId;
      const imageUrls = this.photosInventory;

      // console.log('imagens ',imageUrls);
  
      // Chama o serviço de PDF para gerar o relatório
      await this.pdfService.generatePdfInventory(this.displayData, imageUrls, ref);
    } else {
      console.error('displayData is undefined or empty.');
    }
  }
  

  inventoryDisplayData(displayData: ActivityInventory) {
    this.displayData = [
      { label: 'Id da Atividade', value: displayData.activityId } ,
      { label: 'Id do Inventario', value: displayData.inventoryId },
      { label: 'Id do técnico', value: displayData.resourceId },
      { label: 'Tipo de preventiva', value: displayData.inventoryType },
      { label: 'Modeo do inventario', value: displayData.xirInvModel },
      // { label: 'Endereço', value: displayData.xirGeoCode },      
    ];
  }


  loadPossition(resourceId:string){
    this.isTechnician = true;
    this.technicianPosition = { lat: -22.87778, lng: -46.6333094 };
  }

  // selectInventoryImages(index: number, activityInventoryId: number): void {
  //   if (activityInventoryId) {
  //     console.log('ID do inventário:', activityInventoryId);
  //     this.photosInventory = this.inventoryService.getImageUrlsByActivityId(activityInventoryId,this.inventoryPoints);
  //     console.log(this.photosInventory);
  //     this.showTooltipInventory(index);    
  //   } else {
  //     console.error('O ID do inventário é indefinido.');
  //   }
  // }  

  // selectInventoryImages(index: number, activityInventoryId: number): void {
  //   if (activityInventoryId) {
  //     this.loading = true; // Inicia o loading
  //     console.log('ID do inventário:', activityInventoryId);
  
  //     // Supondo que getImageUrlsByActivityId retorne um Observable<string[]>
  //     this.inventoryService.getImageUrlsByActivityId(activityInventoryId, this.inventoryPoints).subscribe((imageUrls: string[]) => {
  //       this.photosInventory = [];
  
  //       let loadedImages = 0;
  
  //       if (imageUrls.length === 0) {
  //         // Se não houver URLs, finalize o loading imediatamente
  //         this.loading = false;
  //         this.photosInventory.push('/assets/maps/svg-mapa-inventario/no-image.jpeg');
  //         this.showTooltipInventory(index);
  //         return;
  //       }
  
  //       imageUrls.forEach((url) => {
  //         const img = new Image();
  //         img.src = url;
  
  //         img.onload = () => {
  //           loadedImages++;
  //           this.photosInventory.push(url);
  
  //           if (loadedImages === imageUrls.length) {
  //             this.loading = false; // Finaliza o loading quando todas as imagens forem carregadas
  //             this.showTooltipInventory(index);
  //           }
  //         };
  
  //         img.onerror = () => {
  //           loadedImages++;
  //           this.photosInventory.push('/assets/maps/svg-mapa-inventario/no-image.jpeg'); // Imagem de fallback
  
  //           if (loadedImages === imageUrls.length) {
  //             this.loading = false; // Finaliza o loading mesmo se houver falha no carregamento de alguma imagem
  //             this.showTooltipInventory(index);
  //           }
  //         };
  //       });
  //     }, error => {
  //       console.error('Erro ao carregar URLs de imagens:', error);
  //       this.loading = false;
  //       this.photosInventory.push('/assets/maps/svg-mapa-inventario/no-image.jpeg');
  //       this.showTooltipInventory(index);
  //     });
  //   } else {
  //     console.error('O ID do inventário é indefinido.');
  //   }
  // }

  selectInventoryImages(index: number, activityInventoryId: number): void {
    if (activityInventoryId) {
      this.loading = true; // Inicia o loading
      // console.log('ID do inventário:', activityInventoryId);
    
      this.loadImagesForActivity(activityInventoryId, this.inventoryPoints, this.showTooltipInventory.bind(this), index);
    } else {
      console.error('O ID do inventário é indefinido.');
    }
  }
  

  trackByActivityId(index: number, activityInventory: any): number {
    return activityInventory.activityId;
  }

  onMapReady(map) {
    this.map = map;
  }

  // * METODO PARA FILTRO
  onChange(event: string) {
    const [key, values] = event.split(':');
    const currentFilters = this.activeFilters.value;
    currentFilters[key] = values;
    this.filterService.updateFilters<ActivityFilters>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
    // this.operationsService.filterActivities(currentFilters);
    // this.activeFilters.next(currentFilters);
  }


  openZoomImageModal(image: string) {
    // console.log(image);
    this.selectedImage = image;
    this.isModalOpen = true;
  }

  closeZoomImageModal() {
    this.isModalOpen = false;
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  closeModal() {
    this.activeModal.dismissAll();
    // this.activeModal.close();
    this.router.navigate([MapaInventarioComponent.INVENTARIO_URL]);
  }

  onSearch(term: string) {
    this.searchTerm = term;
    if (term && term.length >= 8) {
      this.inventoryService.searchInventory(term);  
    } else {
      this.inventoryService.loadActivitiesInventory().subscribe();
      this.cleanInput(); // Limpa a entrada se o termo de busca for muito curto      
    }
  }

  cleanInput() {
    this.searchTerm = '';
    const filters = this.activeFilters.value;
    filters.activityId = '';    
    this.inventoryService.loadActivitiesInventory().subscribe();    
  }

  showTooltipActivity(index: number) {
    this.showInfoWindow[index] = true;    
  } 

  showTooltipInventory(index: number) {    
    this.showInfoWindowInventory[index] = true;    
  } 



  clearMapInfo() {
    this.mapZoom = 4;
    this.isTechnician = false;
    this.markers = [];
    this.mapCenter = { lat: -23.5505199, lng: -46.6333094 };
    this.selectedActivityId = null;        
    this.showInfoWindow = this.showInfoWindow.map(() => false);
    this.showInfoWindowInventory = this.showInfoWindowInventory.map(() => false);
  }
}
