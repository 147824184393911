<div>
  <div>
    <div class="row h-20 selects-row">

      <div class="col-lg-4 col-md-4">
        <app-data-picker-default
          [dates]="getRangeDate()"
          (event)="onChange($event)"
        >
        </app-data-picker-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="causes"
          title="Causas"
          [dropdownList]="filters.causes"
          [selectedItems]="['VANDALISMO']"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="families"
          title="Família"
          [dropdownList]="filters.families"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="regionals"
          title="Regionais"
          [dropdownList]="this.data.value.filters?.dropdown?.regionals"
          [selectedItems]="this.data.value.filters?.selected?.regionals"
          [itemsShowLimit]="1"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="directors"
          title="Diretorias"
          [limit]="1"
          [dropdownList]="this.data.value.filters?.dropdown?.directors"
          [selectedItems]="this.data.value.filters?.selected?.directors"
          [itemsShowLimit]="1"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div class="col-lg-2 col-md-2"></div>
      <div class="col-lg-2 col-md-2"></div>
    </div>

    <div class="row margin-bottom-2vh">

      <div class="views-container">
        <button
          class="view-button"
          (click)="setView('analyticalTicketsByGroup')"
          [class.active]="view.value === 'analyticalTicketsByGroup'"
        > Atividades por diretoria
        </button>

        <button
          class="view-button"
          (click)="setView('analyticalPhotosByTechnical')"
          [class.active]="view.value === 'analyticalPhotosByTechnical'"
        >Atividades por técnico
        </button>

        <button
          class="view-button"
          (click)="setView('analyticalTicketsByYear')"
          [class.active]="view.value === 'analyticalTicketsByYear'"
        >Atividades por mês 
        </button>

        <button
          class="view-button"
          (click)="setView('analyticalPhotosByValidation')"
          [class.active]="view.value === 'analyticalPhotosByValidation'"
        >Fotos por validação 
        </button>
      </div>
    </div>

    <div class="views-reports">
      <button
        class="styled-button red-button"
        (click)="openReportModal('worst')"
      >
        Gerar Relatório
      </button>
    </div>

    <div class="row">
      <div class="card-chart chart-full col-12 col-md-12 col-xxl-12 chart-height">
        <div class="card-chart-header chart-full-header">
          <p class="chart-title">{{ data.value[this.view.value]?.title }}</p>
        </div>
        <hr>
    
        <!-- Verifica se está carregando -->
        <div *ngIf="loading.value" class="loading-container d-flex flex-column justify-content-center align-items-center expand-max">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Carregando...</span>
          </div>
          <p class="loading-text mt-3">Estamos carregando os dados. Por favor, aguarde.</p>
        </div>

        <!-- Verifica se houve erro na requisição -->
        <div *ngIf="error.value && !loading.value" class="error-container d-flex flex-column justify-content-center align-items-center expand-max">
          <fa-icon [icon]="icons.danger" class="text-danger" size="3x"></fa-icon>
          <p class="error-text mt-3">Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.</p>
        </div>

        <!-- Verifica se não encontrou o conteúdo -->
        <div *ngIf="!loading.value && !error.value && !isFoundContent" class="not-found-container d-flex flex-column justify-content-center align-items-center expand-max">
          <fa-icon [icon]="icons.search" class="text-muted" size="3x"></fa-icon>
          <p class="not-found-text mt-3">Nenhum conteúdo foi encontrado para os filtros aplicados.</p>
        </div>
      
        <div *ngIf="!loading.value && !error.value && isFoundContent" class="expand-max">
          <div class="legend-container">
            <ng-container *ngFor="let legend of data.value[this.view.value]?.legends; let i = index">
              <span class="legend-dot" [ngStyle]="{'background-color': colors[i] }"></span>
              <span class="legend-text">{{ legend }}</span>
            </ng-container>
          </div>
    
          <div style="height: 100%">
            <div class="d-flex chart-container" [ngStyle]="{ 'width': barWidthProportion }">
              <canvas
                baseChart
                height="90"
                width="100"
                [datasets]="barChartData.datasets"
                [labels]="barChartData.labels"
                [options]="options"
                [legend]="false"
                [chartType]="'bar'"
              >
              </canvas>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <div class="row margin-top-4vh" *ngIf="showTable.value">

      <div class="views-reports" *ngIf="isAnalyticalPhotosByTechnical()">
        <button
          class="styled-button red-button"
          (click)="openReportModal('validation')"
        >
          Gerar Relatório
        </button>
      </div>

      <div class="div-container">
        <table class="image-verification-table">
          <tr class="image-verification-row">
            <th class="image-verification-header">Id da atividade</th>
            <th class="image-verification-header">Id do incidente</th>
            <th class="image-verification-header">Número da imagem</th>
            <th class="image-verification-header">Id do Técnico</th>
            <th class="image-verification-header" *ngIf="isAnalyticalPhotosByTechnical()">Empresa</th>
            <th class="image-verification-header" *ngIf="isAnalyticalPhotosByTechnical()">Validações</th>
          </tr>
          <tr
            *ngFor="let ticket of ticketsTable.value; let i = index"
            class="image-verification-row"
          >
            <td class="image-verification-body image-verification-body-hover" (click)="openTicketPopUp(ticket[0])">{{ ticket[0] ?? '' }}</td>
            <td class="image-verification-body">{{ ticket[1] ?? '' }}</td>
            <td class="image-verification-body">{{ ticket[2] ?? '' }}</td>
            <td class="image-verification-body">{{ ticket[3] ?? '' }}</td>
            <td class="image-verification-body" *ngIf="isAnalyticalPhotosByTechnical()" >{{ ticket[4] ?? '' }}</td>
            <td class="image-verification-body" *ngIf="isAnalyticalPhotosByTechnical()">{{ticket[5] ?? ''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
