<div class="card-chart chart-small-geral col-12 col-md-12 col-xxl-12">

  <app-request-wrapper
    class="chart-small-body-analytic"
    [isLoading]="loading"
    [isError]="error"
  >
    <div class="card-chart-header chart-small-header-analytic">
      <div>
        <app-title-default [content]="barAnalytic.title"></app-title-default>
      </div>
    </div>
    <hr>
    <div
      class="card-chart-body card-chart-body-vertical chart-small-body-analytic"
      style="display: flex; align-items: center; justify-content: center;"
    >
      <div 
        style="width: 100%; height: 100%; position: relative;"
        [ngStyle]="{ 'width': barWidthProportion }"
      >
        <canvas
          id="myChart"
          baseChart
          height="90"
          width="100"
          [datasets]="barAnalytic.dataset"
          [labels]="barAnalytic.labels"
          [options]="options"
          [legend]="false"
          [chartType]="type"
          >
        </canvas>
      </div>
      <div id="chartjs-tooltip" style="position: absolute; background-color: rgba(0, 0, 0, 0.7); color: white; padding: 10px; border-radius: 3px; display: none;"></div>
    </div>
  </app-request-wrapper>
</div>