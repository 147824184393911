export enum LegendType {
  CIRCLE='CIRCLE',
  TRIANGLE_DEFAULT='TRIANGLE',
  TRIANGLE_LIGHT='TRIANGLE_LIGHT',
  TRIANGLE_YELLOW = 'TRIANGLE_YELLOW'
}
export interface LegendItem<T> {
  name: T;
  color: string;
  isVisible: boolean;
  type: LegendType
}
