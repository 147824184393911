
export namespace AccessControl {

  export interface Params {
    startDate: string,
    endDate: string,
    directors: string,
    operations: string
    companies: string
  }

  export interface Filters {
    directors: string[],
    operations: string[],
    companies: string[]
  }

  export interface Response<T> {
    message: string,
    filters: Filters,
    data: T
  }

  export class Output<T> {

    response: Response<T>;
    
    constructor(response?: Response<T>) {
      if (response) {
        this.response = response;
      }
    }
  }
}