import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccessControl } from 'src/app/interfaces/dtos/AccessControl';
import { AuthService } from 'src/app/services/auth.service';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { UserService } from 'src/app/services/user.service';
import { GenerateDate } from 'src/app/utils/generateDate.util';
import { LogsPopupComponent } from './components/logs-popup.component';
import { defaultActiveFilters, initialDataDefault } from './utils/constants';
import { UserMetricsLog } from 'src/app/interfaces/models/IUserMetricsLog';

@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: []
})
export class AccessControlComponent implements OnInit {

  private NAMESPACE: Namespace = 'accesscontrol';

  data: BehaviorSubject<AccessControl.Response<UserMetricsLog[]>> = new BehaviorSubject(initialDataDefault);
  activeFilters: AccessControl.Params = defaultActiveFilters;
  filteredLogs = [];
  searchTerm: string = '';
  loading: boolean = true;
  error: boolean = false;
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,    
    layout: {
      padding: {
        top: 15,
        left: -5,
        right: -5,
        bottom: 0
      }
    },
    scales: { 
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: true,
          beginAtZero: true
        }
      }],
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true
          },
          gridLines: {
            display: false
          },
        }
     ] 
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        color: 'black',
        offset: -4,
        font: { weight: 'bold', size: 11 },
      }
    }
  };

  barWidthProportion = '100%'
  colors: string[] = ['red'];
  requestWrapperConfig = {
    chart: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary height-chart-image-verification',
  }

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private filterService: FilterService,
    private userService: UserService
  ) {

    this.filterService.setDefaultFilters<AccessControl.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<AccessControl.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(500))
      .subscribe((af) => {
        this.activeFilters = af;
        this.fetchData(af)
      });
  }

  fetchData(activeFilters: AccessControl.Params) {
    this.loading = true;

    return this.userService.getLogs(activeFilters)
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        const output = new AccessControl.Output({
          ...response,
          data: response.data.map((d) => ({
            userId: d[0],
            username: d[1],
            count: d[2],
            company: d[3]
          }))
        });
        this.data.next(output.response);
        this.filteredLogs = this.data.value.data;
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  applyFilter() {
    if (!this.searchTerm) {
      this.filteredLogs = this.data.value.data;
    } else {
      this.filteredLogs = this.data.value.data.filter((log) =>
        log.username.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  ngOnInit(): void {}

  isAdmin() {
    return this.authService.isUserVirtsel();
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDateMinusDays(1);
    return { startDate, endDate }
  }

  openModal(userId, username) {
    const modalRef = this.modalService.open(LogsPopupComponent, { size: 'lg' });
    modalRef.componentInstance.userInfo = { userId, username };
    modalRef.componentInstance.activeFilters = this.activeFilters;
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    let newValues = values;
    this.filterService.updateFilters<AccessControl.Params>({
      namespace: this.NAMESPACE,
      key,
      values: newValues,
    });
  } 
}
